<template>
  <form @submit.prevent="reAuthWithCredential">
    <div class="modal-card">
      <header class="modal-card-head has-background-primary">
        <p class="modal-card-title has-text-white has-text-centered">
          Please re-authenticate
        </p>
      </header>

      <section class="modal-card-body">
        <p class="has-margin-bottom-150">
          To continue with this sensitive operation, please
          {{
            hasLinkedCredential
              ? "enter your current password"
              : "re-authenticate using one of your linked accounts"
          }}.
        </p>

        <div v-if="hasLinkedCredential">
          <b-field label="Current Password">
            <b-input
              ref="password"
              v-model="form.password"
              required
              type="password"
              placeholder="Your current password"
            />
          </b-field>
        </div>

        <div
          v-else
          :class="{ 'is-centered': linkedOAuthProviders().length === 1 }"
          class="columns is-multiline"
          style="margin: -0.25em;"
        >
          <div
            v-for="p in linkedOAuthProviders()"
            :key="p.provider.providerId"
            class="column is-6 has-padding-25"
          >
            <button
              :class="{ 'is-loading': processing === p.provider().providerId }"
              :disabled="!!processing"
              type="button"
              class="button is-medium is-fullwidth"
              @click="reAuthWithProvider(p)"
            >
              <b-icon
                v-if="processing !== p.provider().providerId"
                :icon="p.icon.code"
                :pack="p.icon.pack"
                :style="{ color: p.color }"
                size="is-small"
              />

              <span class="is-size-6">{{ p.label }}</span>
            </button>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot has-content-justified-between">
        <button
          :disabled="!!processing"
          type="button"
          class="button is-light"
          tabindex="-1"
          @click="$emit('cancel')"
        >
          Cancel
        </button>

        <button
          v-if="hasLinkedCredential"
          :class="{ 'is-loading': processing === 'password' }"
          :disabled="formIsDisabled"
          type="submit"
          class="button is-success"
        >
          Submit
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ReauthenticateModal",
  data() {
    return {
      processing: "",
      form: {
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      linkedProviders: ["auth/linkedProviders"],
      linkedOAuthProviders: ["auth/linkedOAuthProviders"]
    }),
    hasLinkedCredential() {
      return this.$_.filter(this.linkedProviders(), p => {
        return p.provider().providerId === "password";
      }).length;
    },
    formIsValid() {
      return !!this.form.password.length;
    },
    formIsDisabled() {
      return !this.formIsValid || !!this.processing;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.hasLinkedCredential) {
        this.$refs.password &&
          this.$refs.password.$el.querySelector("input").focus();
      }
    });
  },
  methods: {
    reAuthWithCredential() {
      if (this.formIsDisabled) return;
      this.processing = "password";
      this.$store
        .dispatch("auth/reauthenticateWithCredential", {
          password: this.form.password
        })
        .then(() => this.reAuthSuccess())
        .catch(error => this.reAuthError(error));
    },
    reAuthWithProvider(p) {
      this.processing = p.provider().providerId;
      this.$store
        .dispatch("auth/reauthenticateWithPopup", {
          provider: p.provider()
        })
        .then(() => this.reAuthSuccess())
        .catch(error => this.reAuthError(error));
    },
    reAuthSuccess() {
      this.$emit("authenticated");
    },
    reAuthError(error) {
      this.processing = "";
      this.$toast.open({
        message: `${error.message}`,
        position: "is-bottom",
        type: "is-danger",
        queue: false,
        duration: 3000
      });
    }
  }
};
</script>
